import React from "react";
import styles from "../styles/LicenseShareBar.module.css";
import coolIcon from "/public/images/coolicon.svg";
import getImageSrc from "../lib/getImageSrc";

export default function LicenseSharingBar(props) {
  return (
    <>
      <div className={styles.sharingbar}>
        <p className={styles.sharingText}>
          {`LET OP: naast de zero-emissiezones zijn er ook milieuzones met andere
          toegangsregels. Check `}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.milieuzones.nl/"
            className={`${styles.link} ${styles.inline}`}
          >
            <img
              alt=""
              className={styles.linkImage}
              width={14}
              height={14}
              src={getImageSrc(coolIcon)}
            />
            {" hier "}
          </a>
          of je daar toegang hebt.
        </p>
      </div>
      <div className={styles.sharingbarMoreInfo + " moreInfo"}>
        <p className={styles.sharingText}>Meer weten?</p>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.opwegnaarzes.nl/bedrijven"
          className={styles.link}
        >
          <img alt="" width={16} height={16} src={getImageSrc(coolIcon)} />
          Kijk op opwegnaarzes.nl
        </a>
      </div>
    </>
  );
}
