import React from "react";
import { createRoot } from "react-dom/client";

import LicenseplateFormCard from "./components/licenseplate-form-card";

window.addEventListener("DOMContentLoaded", () => {
  const checkers = document.querySelectorAll(
    "[data-zes-kentekenchecker], .zes-kentekenchecker"
  );
  checkers.forEach((el) => {
    const root = createRoot(el);
    root.render(<LicenseplateFormCard apiUrl="https://kentekencheck.opwegnaarzes.nl/api" />);
  });
});
